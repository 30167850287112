@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        @apply h-full w-full p-0 m-0;
    }

    #__next {
        @apply h-full;
    }

    .content {
        a {
            @apply underline;
        }

        .profession a {
            @apply text-black !important;
        }
        
    }
    
    a:hover {
        @apply underline;
    }

    *,
    *::before,
    *::after {
        @apply box-border;
    }

    h1 {
        @apply text-4xl font-bold mt-4 mb-2;
    }

    h2 {
        @apply text-3xl font-bold mt-4 mb-2;
    }

    h3 {
        @apply text-2xl font-bold mt-4 mb-2;
    }

    h4 {
        @apply text-xl font-bold mt-4 mb-2;
    }

    h5 {
        @apply text-lg font-bold mt-4 mb-2;
    }

    h6 {
        @apply text-base font-bold mt-4 mb-2;
    }

    p {
        @apply my-4 text-base leading-relaxed;
    }

    ul,
    ol {
        @apply pl-5 my-4 list-disc leading-4;
    }

    ol {
        @apply list-decimal;
    }

    li {
        @apply mb-2;
    }

    img {
        @apply max-w-full h-auto;
    }

    .product-main-image img {
        @apply w-[unset] h-[unset] max-w-[unset] max-h-[unset];
    }
}


